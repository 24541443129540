import { toast } from 'react-toastify'
import errorTypes from './toastTypes'

export const handleError = (errorType) => {
  let message

  if (errorTypes[errorType]) {
    message = errorTypes[errorType]
  } else if (typeof errorType === 'string') {
    message = errorType
  } else {
    message = errorTypes.GENERIC_ERROR
  }

  toast.error(message, { theme: 'colored', autoClose: 3000 })
}

export const handleSuccess = (message) => {
  toast.success(message, { theme: 'colored', autoClose: 500 })
}

export const handleWarning = (message) => {
  toast.warn(message, { theme: 'colored', autoClose: 3000 })
}

export const handleInfo = (message) => {
  toast.info(message, { theme: 'colored', autoClose: 3000 })
}
