import {
  FaBell,
  FaBolt,
  FaCheck,
  FaExclamation,
  FaInfoCircle,
} from 'react-icons/fa'

export const getIconByType = (type) => {
  switch (type) {
    case 'danger':
      return <FaExclamation title="riesgo" size={18} className="text-danger" />
    case 'info':
      return (
        <FaInfoCircle title="información" size={18} className="text-info" />
      )
    case 'success':
      return <FaCheck title="éxito" size={18} className="text-success" />
    case 'warning':
      return <FaBolt title="aviso" size={18} className="text-warning" />
    default:
      return <FaBell size={18} className="text-dark" />
  }
}
