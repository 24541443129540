import * as types from '../constants'
import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'

export function loadUserReminders() {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-reminders`,
      method: 'GET',
      types: [
        types.LOAD_USER_REMINDERS_REQUEST,
        types.LOAD_USER_REMINDERS_SUCCESS,
        types.LOAD_USER_REMINDERS_ERROR,
      ],
    },
  }
}

export function markAsReadOrArchivedUserReminders(update, type) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-reminders/status/${type}`,
      method: 'PATCH',
      body: JSON.stringify(update),
      types: [
        types.UPDATE_USER_REMINDERS_STATUS_REQUEST,
        types.UPDATE_USER_REMINDERS_STATUS_SUCCESS,
        types.UPDATE_USER_REMINDERS_STATUS_ERROR,
      ],
    },
  }
}

export function createUserReminder(reminder) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/user-reminders/`,
      method: 'POST',
      body: JSON.stringify(reminder),
      types: [
        types.CREATE_USER_REMINDER_STATUS_REQUEST,
        types.CREATE_USER_REMINDER_STATUS_SUCCESS,
        types.CREATE_USER_REMINDER_STATUS_ERROR,
      ],
    },
  }
}
