import React, { useState } from 'react'
import { Row, Col, ListGroupItem } from 'reactstrap'
import { FaEnvelope, FaEnvelopeOpen, FaArchive } from 'react-icons/fa'
import moment from 'moment'
import { markAsReadOrArchivedUserReminders } from '../../redux/actions/userReminder'
import { useDispatch } from 'react-redux'
import { getIconByType } from './utils/navbarDropdownItemUtils'

const NavbarDropdownItem = ({
  isRead,
  onArchive,
  onMarkAsRead,
  markedAsRead,
  isDashboard,
  notification,
}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)

  const { message, created_at, _id, type, isArchived, isMultiple } =
    notification
  const shouldMuteText = isRead || isArchived?.state
  const textStyle = shouldMuteText ? 'text-muted' : 'fw-bold'
  const createdAtMoment = moment(created_at)
  const currentTime = moment()
  const isWithinEightHours =
    isMultiple && currentTime.diff(createdAtMoment, 'hours') < 8
  const muted = isRead || markedAsRead?.some((item) => item === _id)

  const handleClickIsRead = () => {
    if (!isRead && !isMultiple) {
      if (isDashboard) {
        dispatch(
          markAsReadOrArchivedUserReminders(
            { userReminderIds: [_id] },
            'isRead'
          )
        )
      } else {
        onMarkAsRead(_id)
      }
    }
  }

  const handleClickarchived = () => {
    if (!isMultiple) onArchive(_id)
  }

  return (
    <ListGroupItem
      style={{
        opacity: isArchived?.state ? 0.5 : 1,
        width: isDashboard ? '100%' : '350px',
        backgroundColor: isWithinEightHours
          ? 'rgba(66, 164, 255, 0.4)'
          : isArchived?.state && isRead
          ? 'rgba(93, 102, 117, 0.15)'
          : 'transparent',
        border: 'none',
        borderBottom: '1px solid var(--bs-list-group-border-color)',
      }}
    >
      <Row className="align-items-center g-0">
        <Col xs={2}>{getIconByType(type)}</Col>
        <Col onClick={handleClickIsRead} xs={8} style={{ cursor: 'pointer' }}>
          <div
            className={`small mt-1 ${textStyle}`}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              display: 'block',
              maxWidth: '100%',
              overflow: isExpanded ? 'visible' : 'hidden',
              whiteSpace: isExpanded ? 'normal' : 'nowrap',
              textOverflow: isExpanded ? 'clip' : 'ellipsis',
              WebkitLineClamp: isExpanded ? 'none' : 2,
            }}
          >
            {message}
          </div>
          <div className={`small mt-1 ${textStyle}`}>
            {moment(created_at).format('DD MMM, h:mm A')}
          </div>
        </Col>
        <Col xs={2} className="text-end">
          {!isArchived?.state && isRead && isDashboard ? (
            <FaArchive
              title="archivar"
              className="text-muted"
              style={{ cursor: 'pointer' }}
              onClick={handleClickarchived}
            />
          ) : muted ? (
            <FaEnvelopeOpen title="leído" className="text-muted" />
          ) : (
            !isMultiple && <FaEnvelope title="no leído" />
          )}
        </Col>
      </Row>
    </ListGroupItem>
  )
}

export default NavbarDropdownItem
