import { useEffect, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import { Badge } from 'reactstrap'

const UserReminderBadge = ({ newNotificationsCount, allMarkedAsRead }) => {
  const [isMuted, setIsMuted] = useState(false)

  useEffect(() => {
    setIsMuted(newNotificationsCount === 0)
  }, [newNotificationsCount, allMarkedAsRead])

  return (
    <div className="position-relative">
      <FaBell
        title="notificaciones"
        className={isMuted ? 'text-muted' : 'text-warning'}
      />
      {newNotificationsCount > 0 && (
        <Badge
          color="danger"
          pill
          className="position-absolute top-0 start-100 translate-middle"
          style={{
            backgroundColor: 'red',
            color: 'white',
            fontSize: '0.6rem',
          }}
        >
          {newNotificationsCount}
        </Badge>
      )}
    </div>
  )
}

export default UserReminderBadge
