import React, { useEffect, useState, useCallback } from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadUserReminders,
  markAsReadOrArchivedUserReminders,
} from '../../redux/actions/userReminder'
import NavbarDropdownItem from '../NavbarDropdownItem/NavbarDropdownItem'
import { FormGroup, Input, Label, ListGroup } from 'reactstrap'
import UserReminderBadge from './components/UserReminderBadge'
import NavbarDropDown from '../NavbarDropDown/NavbarDropDown'
import moment from 'moment'

const UserReminders = ({ isDashboard }) => {
  const dispatch = useDispatch()
  const userReminders = useSelector((state) => state.userReminder.userReminders)
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [markedAsRead, setMarkedAsRead] = useState([])
  const [allMarkedAsRead, setAllMarkedAsRead] = useState(false)
  const [newNotificationsCount, setNewNotificationsCount] = useState(null)

  useEffect(() => {
    dispatch(loadUserReminders())
  }, [dispatch])

  useEffect(() => {
    const count = userReminders.filter((notification) => {
      const isMultiple = notification.isMultiple
      const createdAt = moment(notification.created_at)
      const currentTime = moment()

      const isWithinEightHours = isMultiple
        ? currentTime.diff(createdAt, 'hours') <= 8
        : true

      return (
        !notification.isArchived?.state &&
        isWithinEightHours &&
        (isMultiple ||
          (!notification.isRead?.state &&
            !markedAsRead.includes(notification._id)))
      )
    }).length

    setNewNotificationsCount(count)
  }, [userReminders, markedAsRead])

  const filteredMessages = isActive
    ? userReminders
    : userReminders.filter(
        (notification) =>
          !notification.isArchived?.state && !notification.isRead?.state
      )

  const handleDropdownClose = useCallback(() => {
    if (markedAsRead.length > 0) {
      dispatch(
        markAsReadOrArchivedUserReminders(
          { userReminderIds: markedAsRead },
          'isRead'
        )
      )
      setMarkedAsRead([])
    }
  }, [dispatch, markedAsRead])

  const handleMarkAsRead = useCallback((id) => {
    setMarkedAsRead((prev) => [...new Set([...prev, id])])
  }, [])

  const handleArchive = (id) => {
    dispatch(
      markAsReadOrArchivedUserReminders({ userReminderIds: [id] }, 'isArchived')
    )
  }

  const handleMarkAllAsRead = useCallback(() => {
    const nonMultipleIds = filteredMessages
      .filter((notification) => !notification.isMultiple)
      .map((notification) => notification._id)

    dispatch(
      markAsReadOrArchivedUserReminders(
        { userReminderIds: nonMultipleIds },
        'isRead'
      )
    )

    setMarkedAsRead((prev) => [...new Set([...prev, ...nonMultipleIds])])
    setAllMarkedAsRead(true)
  }, [dispatch, filteredMessages])

  const allVisibleAreMultiple = filteredMessages.every(
    (notification) => notification.isMultiple
  )

  if (isDashboard) {
    return (
      <div
        className="selected-blue"
        style={{
          backgroundColor: 'white',
          maxHeight: '500px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {newNotificationsCount > 0 && !allMarkedAsRead ? (
          <div
            className="dropdown-menu-header p-2 border-bottom"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <div className="d-flex flex-column align-items-start">
              <div className="text-center mt-1 w-100">
                <span className="fw-bold text-black">
                  {newNotificationsCount > 1 ? (
                    <>
                      <span className="text-danger">
                        {newNotificationsCount}
                      </span>{' '}
                      Nuevas notificaciones
                    </>
                  ) : (
                    <>
                      <span className="text-danger">
                        {newNotificationsCount}
                      </span>{' '}
                      Nueva notificación
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dropdown-menu-header p-2 border-bottom-0"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <div className="text-center mt-1 w-100">
              <span className="fw-bold text-muted">
                No hay nuevas notificaciones
              </span>
            </div>
          </div>
        )}

        {newNotificationsCount > 0 && !allMarkedAsRead && (
          <div
            className="d-flex align-items-center px-3 justify-content-center border-bottom"
            style={{ backgroundColor: '#f5f9fc' }}
          >
            <div className="d-flex flex-row justify-content-center align-items-center mb-3 mt-2">
              <span className="me-2">Marcar todos como leídos</span>
              <FaEnvelope
                className="fw-bold cursor-pointer"
                style={{ color: '#42a4ff' }}
                onClick={handleMarkAllAsRead}
                size={20}
              />
            </div>
          </div>
        )}

        <div
          style={{
            overflowY: 'auto',
            flexGrow: 1,
          }}
        >
          <ListGroup>
            {userReminders.length > 0 ? (
              userReminders.map((notification) => (
                <NavbarDropdownItem
                  key={notification._id}
                  isRead={
                    markedAsRead.includes(notification._id) ||
                    notification.isRead?.state
                  }
                  onMarkAsRead={handleMarkAsRead}
                  onArchive={handleArchive}
                  isDashboard={isDashboard}
                  notification={notification}
                  markedAsRead={markedAsRead}
                  setMarkedAsRead={setMarkedAsRead}
                />
              ))
            ) : (
              <p className="text-muted p-3">No notifications available.</p>
            )}
          </ListGroup>
        </div>
      </div>
    )
  }

  // Render como Dropdown si no estamos en el dashboard
  return (
    <NavbarDropDown
      icon={
        <UserReminderBadge
          newNotificationsCount={newNotificationsCount}
          allMarkedAsRead={allMarkedAsRead}
        />
      }
      newNotificationsCount={newNotificationsCount}
      filteredMessages={filteredMessages}
      handleMarkAllAsRead={handleMarkAllAsRead}
      isOpen={navbarDropdownOpen}
      toggle={() => setNavbarDropdownOpen((prev) => !prev)}
      onDropdownClose={handleDropdownClose}
      allMarkedAsRead={allMarkedAsRead}
      href={'/panel/client-dashboard'}
      isDashboard={isDashboard}
      reminders={userReminders}
    >
      {
        <div
          className="d-flex align-items-center px-3 justify-content-between border-bottom"
          style={{ backgroundColor: '#f5f9fc' }}
        >
          <FormGroup className="form-check form-switch d-flex justify-content-between align-items-center mt-2">
            <Input
              id="viewAll"
              type="checkbox"
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              style={{ cursor: 'pointer' }}
              className="form-check-input"
            />
            {!isActive && <Label className="ms-2 w-auto">Ver histórico</Label>}
          </FormGroup>

          {newNotificationsCount > 0 &&
            !allMarkedAsRead &&
            !allVisibleAreMultiple && (
              <div className="d-flex flex-row justify-content-center align-items-center mb-3 mt-2">
                <span className="me-2">Marcar como leídos</span>
                <FaEnvelope
                  className="fw-bold text-info cursor-pointer"
                  onClick={handleMarkAllAsRead}
                  style={{ color: '#42a4ff' }}
                  size={20}
                />
              </div>
            )}
        </div>
      }

      <ListGroup className="p-0">
        {filteredMessages.length > 0 &&
          filteredMessages
            .slice(0, 5)
            .map((notification) => (
              <NavbarDropdownItem
                key={notification._id}
                isRead={
                  markedAsRead.includes(notification._id) ||
                  notification.isRead?.state
                }
                onMarkAsRead={handleMarkAsRead}
                onArchive={handleArchive}
                isDashboard={isDashboard}
                notification={notification}
                markedAsRead={markedAsRead}
              />
            ))}
      </ListGroup>
    </NavbarDropDown>
  )
}

export default UserReminders
